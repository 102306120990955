@media screen and (max-width: 767px) {
    .nieuwbouw__item {
        margin-bottom: 40px;
    }

    .nieuwbouw__item__text {
        font-size: 15px;
        bottom: 20px;
    }

    .header__top {
        padding: 30px 0;
    }

    .header__top--container {
        flex-direction: column;
    }

    .header__advies,
    .header__openingstijden {
        margin: 0 0 10px;
        width: 100%;

        b {
            display: block;
        }
    }

    .home__intro {
        padding: 0 0 40px;
    }

    .home__action {
        flex-direction: column;
        text-align: center;
        padding: 25px 30px;

        h2 {
            font-size: 28px;
        }
    }

    .home__action--content {
        padding: 0;
    }

    .home__action--subtitle {
        font-size: 19px;
    }

    .home__action--image {
        width: 60%;
        margin: 50px auto 0;
    }

    .footer__top {
        padding: 40px 0 0;
    }

    .block__intro {
        margin-bottom: 20px;
    }

    .occasions__intro {
        flex-direction: column;
        text-align: center;
    }

    .footer__bovag {
        flex-direction: column;
        justify-content: center;
    }

    .footer__bovag--image {
        margin: 0;
    }

    .footer__bovag--text {
        text-align: center;
        margin-top: 10px;
        display: block;
    }

    .footer__bottom--menu {
        text-align: center;
    }

    .other__projects {
        padding: 40px 0;
    }

    .project__overlay {
        font-size: 26px;
    }

    .timeline__circle {
        &::before { width: 250px; }
    }

    .timeline__title {
        font-size: 22px;
    }

    .timeline__text {
        font-size: 16px;
    }
}
