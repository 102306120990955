.header {
    position: relative;
    background-color: $primarycolor;
    margin-bottom: 80px;
    font-size: 15px;
    color: #fff;

    &::before {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        background-image: url('../../images/header-track2.png');
        background-size: 826px 443px;
        height: 443px;
        width: 826px;
    }
}

.logo {
    img {
        width: 100%;
    }
}

.header__top {
    padding: 55px 0;
}

.header__top--container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.header__top--contact {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header__advies,
.header__openingstijden {
    display: block;
    float: left;
    margin-right: 40px;

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.header__advies {
    margin-left: 0;

    b {
        font-size: 17px;
        font-weight: 700;
        margin-left: 5px;
    }
}

.header__openingstijden {
    i {
        margin: 2px 10px 0 0;
    }
}

.header__afspraak {
    justify-content: flex-end;
}

.home__navigation {
    .block__item.padding {
        .block__item--content {
            padding: 10px 0 0 0;
        }

        .block__item--title {
            margin-bottom: 10px;
        }
    }
}

.header .nav li {
    @extend .nav-item;
}

.header .nav a {
    @extend .nav-link;
}
