footer {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        background-image: url('../../images/footer-track.png');
        background-size: 443px 560px;
        height: 560px;
        width: 443px;
    }
}

.footer__top {
    background-color: $primarycolor;
    padding: 80px 0 40px;
}

.footer__block {
    position: relative;
    margin-bottom: 40px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);

    a {
        color: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;

        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
}

.footer__block--title {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.footer__block--text {
    font-size: 14px;
}

.footer__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;

    li {
        position: relative;
        padding-left: 17px;
        margin-bottom: 2px;

        &::before {
            content: '\f105';
            font-family: 'Font Awesome 5 Pro';
            font-size: 13px;
            position: absolute;
            top: 3px;
            left: 0;
            transition: all 0.3s ease;
        }

        &:hover {
            &::before {
                left: 3px;
            }
        }
    }
}

.vacature__nummer {
    display: inline-block;
    margin-left: 3px;
    height: 21px;
    width: 21px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.footer__block--top {
    display: block;
    margin-bottom: 15px;
}

.footer__block--bottom {
    display: block;
    margin-bottom: 15px;
}

.footer__social {
    display: inline-block;

    .footer__social {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-left: 7px;
        height: 32px;
        width: 32px;
        border-radius: 16px;
        font-size: 18px;
        font-family: 'Font Awesome 5 Pro';
        color: #fff;

        &.facebook {
            background-color: #3b5998;
        }

        &.twitter {
            background-color: #1da1f2;
        }

        &.linkedin {
            background-color: #0077b5;
        }

        &.instagram {
            background-color: #e4405f;
        }
    }

    a:first-child {
        margin-left: 0;
    }
}

.footer__newsletter {
    .footer__block--title {
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .footer__block--subtitle {
        font-size: 15px;
        margin-bottom: 15px;
    }

    form {
        display: flex;
        margin-top: 20px;
    }

    input {
        height: auto;
        margin: 0;
        padding: 5px 10px;
        border-radius: 4px;
    }

    [type=email] {
        position: relative;
        z-index: 1;
        flex: 1;
    }

    [type=submit] {
        position: relative;
        z-index: 2;
        margin-left: -5px;
    }
}

.footer__bottom {
    background-color: #fff;
    padding: 20px 0;
    font-size: 12px;
}

.footer__bovag {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer__bovag--image {
    height: 70px;
    margin-right: 20px;

    img {
        height: 100%;
    }
}

.footer__bottom--menu {
    margin-top: 17px;
    text-align: right;
}
