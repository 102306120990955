/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$font-primary: 'Open Sans', sans-serif;
$font-secondary: '';

$font-color: #545454;
$font-color-dark: #313131;

$primarycolor: #e51737;
$secondarycolor: #ec821c;

$theme-colors: (
    primary: $primarycolor
);

$global-radius: 13px;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
$nav-link-padding-y: 1.6rem;
$nav-link-padding-x: 1.6rem;
