.btn-primary {
    background-color: $primarycolor;
    padding: 7px 20px;
    border-radius: 60px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 1.4;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($primarycolor, 5);
        text-decoration: none;
        box-shadow: none;
    }
}

.btn-secondary {
    background-color: #eee;
    padding: 7px 20px;
    border-radius: 60px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: #313131;
    line-height: 1.4;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background-color: $primarycolor;
        color: #fff;
        text-decoration: none;
        box-shadow: none;
    }

    &.white {
        background-color: #fff;

        &:hover,
        &:active,
        &:focus {
            background-color: #f3f3f3;
            color: #313131;
        }

        i,
        &::before {
            color: $primarycolor;
        }
    }
}

.btn--icon {
    position: relative;
    min-width: inherit;
    display: inline-block;
    text-align: left;

    &::after,
    &::before {
        position: absolute;
        font-family: 'Font Awesome 5 Pro';
        left: 13px;
        top: calc(50% - 12px);
    }
}

.btn--next {
    padding-right: 33px;

    &::after {
        right: 11px;
        left: auto;
        content: "\f105";
        font-size: 19px;
        font-weight: 300;
    }
}

.btn--prev {
    padding-left: 33px;

    &::before {
        content: "\f104";
        font-size: 19px;
        font-weight: 300;
    }
}

.btn--next-alt {
    padding-right: 33px;

    &::after {
        right: 11px;
        left: auto;
        content: "\f0da";
        font-size: 18px;
        font-weight: 700;
    }
}

.btn--prev-alt {
    padding-left: 33px;

    &::before {
        content: "\f0d9";
        font-size: 18px;
        font-weight: 700;
    }
}

.btn--plus {
    padding-left: 33px;

    &::before {
        content: "\f067";
        font-size: 14px;
    }
}

.btn--min {
    padding-left: 33px;

    &::before {
        content: "\f068";
        font-size: 14px;
    }
}

.btn--check {
    padding-left: 33px;

    &::before {
        content: "\f00c";
        font-size: 15px;
        font-weight: 700;
    }
}

.btn--play {
    padding-left: 35px;

    &::before {
        content: "\f04b";
        font-size: 12px;
        font-weight: 700;
    }
}

.btn--filter {
    padding-left: 33px;

    &::before {
        content: "\f01e";
        font-size: 12px;
    }
}

.btn--play-alt {
    padding-left: 33px;

    &::before {
        content: "\f144";
        font-size: 16px;
        font-weight: 700;
    }
}

.btn--email {
    padding-left: 33px;

    &::before {
        content: "\f0e0";
        font-size: 13px;
        font-weight: 700;
    }
}

.btn--search {
    padding-left: 33px;

    &::before {
        content: "\f002";
        font-size: 14px;
    }
}

.btn--menu {
    padding-left: 33px;

    &::before {
        content: "\f0c9";
        font-size: 14px;
    }
}

.btn--delete {
    padding-left: 33px;

    &::before {
        content: "\f00d";
        font-size: 14px;
    }
}

.btn--call {
    padding-left: 33px;

    &::before {
        content: "\f095";
        font-size: 14px;
        font-weight: 700;
        transform: rotate(90deg);
    }
}

.btn--calendar {
    padding-left: 35px;

    &::before {
        content: "\f073";
        font-size: 14px;
        font-weight: 500;
        top: calc(50% - 8px);
    }
}
