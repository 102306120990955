/* Pagination numbered */
.content {
    position: relative;
    z-index: 1;
}

.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 15px;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.home__intro {
    position: relative;
    display: block;
    padding: 30px 0 80px;
    font-size: 18px;
    text-align: center;
}

.home__intro--content {
    span {
        color: $primarycolor;
    }
}

.home__projecten--slider {
    display: block;
    float: left;
    width: 50%;

    .home__projecten--slider-image {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        border-radius: $global-radius;
        overflow: hidden;

        img {
            width: 100%;
        }

        .home__projecten--slider__item--prev,
        .home__projecten--slider__item--next {
            background-color: darken($primarycolor, 5);
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 0;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            font-family: 'Font Awesome 5 Pro';
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                display: block;
                margin-top: 2px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 1px;
                height: 100%;
            }
        }

        .home__projecten--slider__item--prev {
            right: 50px;
            border-radius: $global-radius 0 0 0;

            &::before {
                content: '\f104';
            }

            &::after {
                right: 0;
                background: linear-gradient(transparent, rgba(255, 255, 255, 0.3));
            }
        }

        .home__projecten--slider__item--next {
            right: 0;

            &::before {
                content: '\f105';
            }

            &::after {
                left: 0;
                background: linear-gradient(rgba(0, 0, 0, 0.3), transparent);
            }
        }
    }

    .slick-dots {
        text-align: right;
        width: calc(83.334% - 40px);
        margin-right: calc(16.667% + 40px);

        li {
            margin: 0;

            button::before {
                font-size: 11px;
            }

            &.slick-active button::before {
                color: $primarycolor;
                opacity: 1;
            }
        }
    }
}

.home__projecten--slider-content {
    text-align: right;
    margin: 40px calc(16.667% + 40px) 10px 0;
}

.home__projecten--slider-title {
    font-size: 16px;
    color: $primarycolor;
}

.home__projecten--slider-subtitle {
    font-size: 14px;
    font-style: italic;
}

.home__projecten--text {
    display: block;
    float: left;
    width: 50%;
    background-color: $primarycolor;
    border-radius: $global-radius;
    margin: 70px 0 0 -8.33%;
    padding: 70px 55px 70px calc(8.33% + 55px);
    color: #fff;
    font-size: 16px;

    h4 {
        color: #fff;
    }

    .btn-secondary {
        margin-top: 50px;
    }
}

.home__occasions {
    margin: 40px 0;
    font-size: 16px;

    h2 {
        margin-bottom: 0;
    }

    h4 {
        color: $font-color-dark;
    }

    .btn-primary {
        float: right;
    }
}

.home__occasions--slider {
    margin: 20px -15px 50px;
    width: calc(100% + 30px);

    .occasions--item {
        padding-left: 15px;
        padding-right: 15px;
    }

    .slick-prev,
    .slick-next {
        width: 100px;
        height: 100%;

        &::before {
            position: absolute;
            font-size: 25px;
            font-family: 'Font Awesome 5 Pro';
            color: rgba($font-color-dark, 0.29);
            opacity: 1;
        }
    }

    .slick-prev {
        left: -115px;
        background: linear-gradient(to left, rgba(114, 114, 144, 0.11), transparent);
        border-radius: 0 $global-radius $global-radius 0;

        &::before {
            right: 30px;
            content: '\f104';
        }
    }

    .slick-next {
        right: -115px;
        background: linear-gradient(to right, rgba(114, 114, 144, 0.11), transparent);
        border-radius: $global-radius 0 0 $global-radius;

        &::before {
            left: 30px;
            content: '\f105';
        }
    }
}

.occasions--item {
    .block__item--content {
        display: inline-block;
        padding: 10px 10px 10px;
    }

    .block__item--title {
        text-align: left !important;
    }

    .btn-secondary {
        margin-top: 15px;
        display: block;
    }
}

.home__action {
    margin: 50px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #e0e0e0;
    border-radius: $global-radius;
    padding: 55px 25px 55px 55px;

    h2 {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    h4 {
        display: block;
        font-size: 23px;
        font-weight: 700;
        line-height: 1.4;
        color: #313131;
        margin-bottom: 25px;
    }
}

.home__action--content {
    flex: 1;
    padding-right: 40px;
}

.home__action--image {
    width: 40%;

    img {
        width: 100%;
    }
}

.maatwerk__overview {
    .block__item {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }

    .occasions--item {
        margin-bottom: 31px;
    }

    .col__onderdelen {
        margin-bottom: 30px;
    }
}

.occasions__intro {
    display: flex;
    align-items: center;
}

.onderdelen__overview {
    .occasions--item {
        display: inline-block;
        margin-bottom: 20px;

        .block__items {
            ul {
                margin-left: 0;
            }
        }
    }
}

.usp__row {
    display: block;
    border-top: 1px solid #dcdcdc;
    padding: 40px 0;

    ul {
        list-style: none;
        margin-left: 15px;
        padding: 0;

        li {
            position: relative;
            font-size: 14px;
            color: $font-color;
            width: 20%;
            float: left;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }

            i {
                font-size: 14px;
                color: $primarycolor;
                margin-right: 10px;
            }
        }
    }
}

.other__projects {
    background-color: #fff;
    padding: 80px 0;

    h3 {
        text-align: center;
        margin-bottom: 60px;
    }
}

.other__projects--item {
    position: relative;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;

    img {
        width: 100%;
    }

    &:hover {
        .project__overlay {
            visibility: visible;
            opacity: 1;
        }
    }
}

.project__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: rgba($primarycolor, 0.8);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
}

.contact__block {
    text-align: center;
    margin-bottom: 40px;

    a {
        color: $font-color;
    }

    .btn-primary {
        color: #fff;
    }
}

.contact__h1__title {
    display: block;
    font-size: 23px;
    color: $primarycolor;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.15;

    i {
        margin-right: 10px;
    }
}

.contact__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primarycolor;
    font-size: 38px;
    margin-bottom: 30px;
    height: 45px;
}

.contact__bottom {
    padding: 60px 0;
    background-color: #fff;
}

.categorie__block {
    display: block;
    background-color: #fff;
    border-radius: 8px;
    padding: 50px 50px 10px;
    margin-bottom: 40px;
}

.categorie__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

.categorie__item--image {
    width: 70px;
    margin-right: 30px;

    img {
        width: 100%;
    }
}

.categorie__item--content {
    flex: 1;
}

.categorie__item--title {
    display: block;
    margin: 10px 0;
    font-size: 22px;
    font-weight: 700;
    color: $primarycolor;
}

.categorie__item--text {
    font-size: 15px;
}

.occasions__bottom {
    display: block;
    width: 100%;
    margin-top: 60px;
}

.full__width__gallery {
    position: relative;
    display: inline-block;
    width: 100%;

    > div {
        display: block;
        float: left;
    }

    .gallery__item {
        margin-bottom: 30px;
    }
}

.specs__block {
    display: block;
    width: 100%;
    background-color: $primarycolor;
    color: #fff;
    padding: 25px 35px 15px;
    margin-bottom: 10px;
    border-radius: 5px;

    ul {
        margin: 0;
        padding: 0;
    }

    .block__item--left {
        display: block;
        width: 100%;
        font-weight: 700;
    }

    .block__item--right {
        display: block;
        margin-bottom: 10px;
    }
}

.occasions__koopknop {
    display: block;
    margin-bottom: 30px;
    background-color: $primarycolor;
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    transition: all 0.3s ease;

    &:hover {
        color: #fff;
        background-color: darken($primarycolor, 5);
    }
}

#occasions__form {
    display: none;

    .validation_error {
        color: $primarycolor;
        font-size: 14px;
    }

    .validation_message {
        color: $primarycolor;
        font-size: 14px;
        margin-bottom: 10px;
    }

    ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        float: left;
        width: 48.5%;

        &:nth-child(1) {
            margin-right: 3%;
        }

        &:nth-child(3) {
            width: 100%;
        }

        &:nth-child(4) {
            width: 100%;
        }
    }

    input[type=submit] {
        margin-left: 15px;
        background-color: $primarycolor;
        color: #fff;

        &:hover,
        &:focus {
            background-color: $primarycolor;
            color: #fff;
        }
    }
}

.nieuwbouw__item {
    position: relative;
    display: block;
    margin-bottom: 80px;

    &:hover {
        .nieuwbouw__item__image__overlay {
            background: rgba($primarycolor, 0.7);
        }
    }
}

.nieuwbouw__item__image {
    position: relative;
    display: block;
    border-radius: $global-radius;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.nieuwbouw__item__image__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.3));
    transition: all 0.3s ease;
}

.nieuwbouw__item__text {
    position: absolute;
    width: 100%;
    padding: 0 20px;
    bottom: 30px;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 22px;
    font-weight: 700;
    transition: all 0.3s ease;
}
