.timeline-block {
    width: 100%;
    float: left;
    position: relative;
    padding: 40px 0;

    &::before {
        content: "";
        border: none;
        height: calc(100% - 123px);
        margin-top: 123px;
        margin-left: -1.5px;
        position: absolute;
        left: 50%;
        background-image: linear-gradient(to bottom, transparent 30%, #d2d2d2 30%);
        background-size: 1px 17px;
        width: 3px;
    }
}

.timeline-item {
    width: 100%;
    display: block;
    float: left;
    margin-top: 45px;
    transition: all 500ms ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: both;
    position: relative;

    .timeline-item-left {
        .timeline__inner {
            float: left;
        }

        .timeline__content {
            float: left;
        }

        .timeline__circle {
            right: -10px;

            &::before {
                background: linear-gradient(to right, transparent 30%, #d2d2d2 30%);
                background-size: 17px 1px;
                height: 3px;
                right: 100%;
            }
        }
    }

    .timeline-item-right {
        .timeline__inner {
            float: right;
        }

        .timeline__content {
            float: right;
        }

        .timeline__circle {
            left: -10px;

            &::before {
                background: linear-gradient(to left, transparent 30%, #d2d2d2 30%);
                background-size: 17px 1px;
                height: 3px;
                left: 100%;
            }
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        &::after {
            content: '';
            background: #ededed;
            height: calc(100% - 30px);
            width: 8px;
            top: 116px;
            margin-left: -4px;
            position: absolute;
            z-index: 1;
        }
    }
}

.timeline__img {
    position: relative;
    float: left;
    padding: 15px;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 3;
}

.timeline-item-left {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    left: 0;
    padding-right: 120px;
    box-sizing: border-box;

    .timeline__img {
        box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    }
}

.timeline-item-right {
    position: relative;
    z-index: 2;
    float: right;
    width: 50%;
    right: 0;
    padding-left: 120px;
    box-sizing: border-box;

    .timeline__img {
        box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.15);
    }
}

.timeline__circle {
    position: absolute;
    top: 116px;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;

    &::before {
        content: "";
        width: 250px;
        top: 50%;
        margin-top: -1.5px;
        position: absolute;
    }

    &::after {
        content: "";
        width: 19px;
        height: 19px;
        top: 0;
        left: 0;
        border-radius: 100%;
        background-color: $primarycolor;
        border: 3px solid #fff;
        position: absolute;
    }
}

.timeline__inner {
    width: 50%;
    position: relative;
    pointer-events: visible;
}

.timeline__content {
    margin-top: 45px;
    width: 100%;
    cursor: pointer;
}

.timeline__title {
    font-size: rem-calc(36px);
    font-weight: 700;
    color: $primarycolor;
    margin-bottom: 5px;
}

.timeline__text {
    font-size: rem-calc(17px);
    color: $font-color;

    &:hover {
        color: $font-color;
    }
}

.timeline-full-text {
    max-width: 960px;

    &.hide {
        display: none;
    }
}

.timeline-full-text-inner {
    padding: 25px 30px;
}
