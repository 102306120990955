/** Search form */
.search-form {
    @extend .form-inline;
}

.search-form label {
    @extend .form-group;

    font-weight: normal;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;
    @extend .btn-secondary;
}

.footer__block--form {
    .gform_confirmation_wrapper {
        margin-top: 15px;
    }

    .validation_error {
        display: none;
    }

    .validation_message {
        padding: 10px 0 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .gfield_label {
        display: none;
    }

    .gfield {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .btn-primary {
        padding: 8px 12px;
        color: #fff;
        background-color: #000;

        &:hover,
        &:focus {
            color: #fff !important;
            background-color: #000 !important;
        }
    }
}

.form__material_wrapper {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        margin-right: -15px;
        margin-left: -15px;
        padding: 0;
        list-style: none;
    }

    .gfield {
        position: relative;
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
        min-height: 40px;
        margin: 25px 0 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
        color: #a1a1a1;

        &#field_1_7 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .validation_message {
            position: absolute;
            top: 100%;
            left: 15px;
            width: calc(100% - 30px);
            height: 2px;
            text-indent: -9999px;
            background: $primarycolor;
        }

        &::before {
            position: absolute;
            top: 100%;
            left: 15px;
            width: calc(100% - 30px);
            height: 2px;
            content: '';
            background-color: #e5e5e5;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 15px;
            width: 0;
            height: 3px;
            content: '';
            transition: all 600ms ease-in-out;
            background-color: transparent;
        }

        &.form__label-selected {
            transition: all 0.3s ease-in-out;

            .gfield_label {
                top: -22px;
                transition: all 0.3s ease-in-out;
                color: #313131;
            }

            &::after {
                left: 15px;
                width: calc(100% - 30px);
                transition: all 0.3s ease-in-out;
                background-color: $primarycolor;
            }
        }
    }

    .gfield_label {
        position: absolute;
        top: 0;
        left: 25px;
        padding: 0;
        cursor: text;
        transition: all 0.3s ease-in-out;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        color: #a1a1a1;
    }

    .gfield_required {
        margin-left: 3px;
        color: $primarycolor;
    }

    [type=text],
    [type=password],
    [type=date],
    [type=datetime],
    [type=datetime-local],
    [type=month],
    [type=week],
    [type=email],
    [type=number],
    [type=search],
    [type=tel],
    [type=time],
    [type=url],
    [type=color],
    textarea {
        display: block;
        margin-bottom: 0;
        padding: 0 20px;
        resize: none;
        border-color: transparent;
        background-color: transparent;
    }

    textarea {
        height: auto;
        max-height: 100px;
        padding-top: 8px;
        resize: none;
    }

    [type=submit] {
        @extend .btn-primary;

        margin-top: 40px;
    }
}

form {
    label {
        position: relative;
        display: block;
        margin: 0;
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.8;
        color: #333;
    }

    [type=text],
    [type=password],
    [type=date],
    [type=datetime],
    [type=datetime-local],
    [type=month],
    [type=week],
    [type=email],
    [type=number],
    [type=search],
    [type=tel],
    [type=time],
    [type=url],
    [type=color],
    textarea {
        display: block;
        width: 100%;
        height: 42px;
        box-sizing: border-box;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 12px 15px;
        resize: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        font-size: 14px;
        font-weight: 400;
        color: #505050;
        border: 1px solid #e0e0e0;
        border-radius: 0;
        background-color: #fff;
        box-shadow: none;
    }

    textarea {
        height: auto;
    }

    .form--material {
        .form__label {
            position: relative;
            height: 42px;
            margin: 25px 0 0;
            transition: all 0.3s ease-in-out;
            color: #a1a1a1;

            &::before {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 3px;
                content: '';
                background-color: #e5e5e5;
            }

            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 3px;
                content: '';
                transition: all 600ms ease-in-out;
                background-color: transparent;
            }

            &.form__label-selected {
                transition: all 0.3s ease-in-out;

                .form__label-title {
                    top: -22px;
                    transition: all 0.3s ease-in-out;
                }

                &::after {
                    width: 100%;
                    transition: all 0.3s ease-in-out;
                    background-color: $primarycolor;
                }
            }

            &.form__label--textarea {
                height: auto;
                resize: none;
            }
        }

        .form__label-title {
            position: absolute;
            top: 0;
            left: 10px;
            padding: 0;
            cursor: text;
            transition: all 0.3s ease-in-out;
            line-height: 42px;
        }
    }
}

.grecaptcha-badge,
.hidden__field {
    width: 0 !important;
    min-width: 0 !important;
    height: 0 !important;
    min-height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}
