body {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 1.6;
    background-color: #ededed;
    color: $font-color;

    &#tinymce {
        background-color: #fff;
    }
}

.block {
    padding: 50px 0;

    &.projecten__detail {
        padding-bottom: 0;
    }
}

.template-contact {
    .block {
        padding: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primarycolor;
    font-weight: 700;
}

h1,
h2 {
    margin-bottom: 30px;

    &span {
        color: #313131;
    }
}

h3,
h4 {
    margin-bottom: 20px;
}

h5,
h6 {
    margin-bottom: 15px;
}

h1 {
    font-size: 25px;
}

a {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

button {
    border: none;
    outline: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

* {
    outline: none;
}

.banner,
.acf-map {
    display: block;
    height: 415px;
    border-radius: 5px;
    box-shadow: 0 20px 32px rgba(0, 0, 0, 0.1);
    margin-bottom: 80px;
    width: 100%;
}

.acf-map img {
    max-width: inherit !important;
}

.marker__inner {
    text-align: center;
}

.block__item {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    border-radius: $global-radius;
    background-color: #fff;
    transition: all 0.3s ease;

    &.padding {
        padding: 10px;

        .block__item--image {
            border-radius: 8px;
        }

        .block__item--content {
            padding: 10px;
        }

        .block__item--title {
            text-align: center;
        }

        .btn-secondary {
            width: 100%;
            margin-top: 10px;
        }
    }

    .btn-secondary {
        margin-top: 20px;
    }

    ul {
        list-style: none;
        margin-left: 15px;
        padding: 0;

        li {
            position: relative;
            padding-left: 22px;
            font-size: 14px;
            color: $font-color;

            &::before {
                content: '\f00c';
                position: absolute;
                font-family: 'Font Awesome 5 Pro';
                left: 0;
                top: 2px;
                color: #bbb;
                transition: all 0.3s ease;
            }
        }
    }

    &:hover,
    &:active,
    &:focus {
        li {
            &::before {
                color: $primarycolor;
            }
        }

        .btn-secondary {
            background-color: $primarycolor;
            color: #fff;
            text-decoration: none;
        }
    }
}

.block__item--image {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.block__item--price {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px 5px 13px;
    border-radius: $global-radius 0 0 0;
    background-color: darken($primarycolor, 5);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
}

.block__item--title {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 700;
    color: $primarycolor;
    text-transform: uppercase;
}

.block__item--content {
    display: block;
    padding: 30px;
    color: $font-color;
    font-size: 14px;

    .btn-primary,
    .btn-secondary {
        display: block;
    }
}

.block__item--row {
    display: block;
    width: 100%;
    margin-bottom: 5px;
}

.block__item--5 {
    display: inline-block;
    width: 100%;
    margin-bottom: -80px;

    .block__item {
        display: block;
        float: left;
        width: calc(20% - 24px);
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
}

input {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 5px 12px;
    font-size: 13px;

    &::placeholder {
        color: #542976;
    }
}

[type=submit] {
    width: auto;
    border-radius: 4px;
    background-color: #000;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
}

.block__intro {
    margin-bottom: 60px;

    &.centered {
        text-align: center;
    }

    .btn-primary {
        margin-top: 20px;
    }
}

.gallery__item {
    display: block;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;

    img {
        width: 100%;
    }
}
