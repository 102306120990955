@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1450px) {
    .home__occasions--slider {
        width: calc(100% - 230px);
        margin-left: 115px;
    }
}
