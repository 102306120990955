@media screen and (max-width: 380px) {
    .form__material_wrapper .gfield {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home__occasions--slider {
        width: calc(100% - 40px);
        margin-left: 20px;

        .slick-prev,
        .slick-next {
            width: 20px;
            height: 100%;

            &::before {
                position: absolute;
                font-size: 25px;
                font-family: 'Font Awesome 5 Pro';
                color: rgba(#313131, 0.29);
                opacity: 1;
            }
        }

        .slick-prev {
            left: -20px;

            &::before {
                right: 15px;
            }
        }

        .slick-next {
            right: -20px;

            &::before {
                left: 15px;
            }
        }
    }
}
