.nav-primary__overlay {
    position: fixed;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
}

.nav-primary {
    position: relative;
    z-index: 5;
    background-color: #fff;
    border-radius: 5px;
    bottom: -40px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
        color: $font-color;
        text-transform: uppercase;
        font-size: 18px;
        transition: all 0.3s ease;
        padding: 26px 38px;

        &.nav__home {
            color: $primarycolor;
            justify-content: center;
            width: 95px;
        }
    }

    ul,
    li {
        list-style: none;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
        position: relative;
        padding: 0;
        margin: 0;

        .fa-angle-down {
            margin-left: 10px;
        }
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;

        > li {
            background-color: transparent;
        }

        .sub-menu {
            position: absolute;
            min-width: 220px;
            display: none;

            &.sub-menu1 {
                top: 100%;
                left: 0;

                > li {
                    background-color: darken($primarycolor, 5);
                    border-bottom: 1px solid darken($primarycolor, 10);

                    a {
                        display: block;
                    }

                    .fa-angle-right {
                        float: right;
                        margin-top: 4px;
                    }
                }
            }

            &.sub-menu2 {
                top: 0;
                left: 100%;

                > li {
                    background-color: darken($primarycolor, 10);
                    border-bottom: 1px solid darken($primarycolor, 15);

                    a {
                        display: block;
                    }

                    .fa-angle-right {
                        float: right;
                        margin-top: 4px;
                    }
                }
            }

            &.sub-menu3 {
                top: 0;
                left: 100%;

                > li {
                    background-color: darken($primarycolor, 15);
                    border-bottom: 1px solid darken($primarycolor, 20);

                    a {
                        display: block;
                    }
                }
            }
        }
    }
}

// Primary Menu
.no-touchevents {
    .nav-primary {
        .nav {
            > li {
                &:hover,
                &:focus {
                    a {
                        color: darken($primarycolor, 5);
                    }

                    .sub-menu {
                        &.sub-menu1 {
                            display: block;
                        }
                    }
                }
            }

            .sub-menu {
                &.sub-menu1 {
                    > li {
                        &:hover,
                        &:focus {
                            a {
                                color: darken($primarycolor, 10);
                            }

                            .sub-menu {
                                &.sub-menu2 {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.sub-menu2 {
                    > li {
                        &:hover,
                        &:focus {
                            a {
                                color: darken($primarycolor, 15);
                            }

                            .sub-menu {
                                &.sub-menu3 {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.sub-menu3 {
                    > li {
                        &:hover,
                        &:focus {
                            a {
                                color: darken($primarycolor, 20);
                            }
                        }
                    }
                }
            }
        }
    }
}

.touchevents {
    .menu-item-has-children__overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .menu-item-has-children {
        &.menu-item-has-children__open {
            background-color: darken($primarycolor, 5) !important;

            > .menu-item-has-children__overlay {
                display: none;
            }

            > .sub-menu {
                display: block;
            }
        }
    }

    .sub-menu1 {
        .menu-item-has-children {
            &.menu-item-has-children__open {
                background-color: darken($primarycolor, 10) !important;
            }
        }
    }

    .sub-menu2 {
        .menu-item-has-children {
            &.menu-item-has-children__open {
                background-color: darken($primarycolor, 15) !important;
            }
        }
    }
}

// Menu bar icon
.menu__icon {
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 10;
    margin: 0;
    transform: rotate(0deg);
    transition: 500ms ease-in-out;
    cursor: pointer;
    display: none;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 4;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 250ms ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 8px;
        }

        &:nth-child(4) {
            top: 16px;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
