.sidebar__block {
    position: relative;
    display: block;
    border-radius: 4px;
    overflow: hidden;
}

.sidebar__block--image {
    position: relative;
    display: block;
    width: 100%;

    img {
        width: 100%;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        content: '';
        background: linear-gradient(transparent, #fff);
    }
}

.sidebar__block--content {
    display: block;
    padding: 40px;

    h6 {
        font-size: 23px;
        font-weight: 500;
    }

    .btn-primary {
        width: 100%;
        margin-top: 10px;
    }
}

.sidebar__questions {
    background-color: #fff;
}

.sidebar__gallery {
    display: block;
    margin-bottom: 20px;

    .gallery__item {
        margin-bottom: 10px;
    }
}

.sidebar__gallery--small {
    margin-left: -5px;
    margin-right: -5px;

    .sidebar__gallery--small-item {
        padding-left: 5px;
        padding-right: 5px;

        img {
            border-radius: 5px;
            overflow: hidden;
            width: 100%;
        }
    }
}
