@media screen and (max-width: 575px) {
    body {
        font-size: 15px;
    }

    .nav-primary {
        a {
            padding: 10px 15px;
            font-size: 15px;

            &.nav__home {
                padding: 10px 15px;
            }
        }
    }

    .logo {
        display: block;
        margin: 0 auto 20px;
        width: 50%;
    }

    .block__item--5 {
        margin-bottom: 10px;

        .block__item.padding {
            align-items: center;
            margin-bottom: 10px;

            ul {
                display: none;
            }

            .block__item--image {
                width: 25%;
            }

            .block__item--content {
                width: 75%;
                padding-left: 15px;
            }

            .block__item--title {
                padding: 10px 0;
                font-size: 12px;
            }
        }
    }

    .nieuwbouw__item {
        margin-bottom: 20px;
    }

    .header__advies,
    .header__openingstijden {
        text-align: center;
    }

    .header__afspraak {
        justify-content: center;
    }

    .home__projecten--slider {
        .home__projecten--slider-image {
            .home__projecten--slider__item--prev,
            .home__projecten--slider__item--next {
                width: 40px;
                height: 40px;
            }

            .home__projecten--slider__item--prev {
                right: 40px;
            }
        }
    }

    .home__projecten--text {
        padding: 20px;
        font-size: 15px;

        h4 {
            font-size: 18px;
        }

        .btn-secondary {
            margin-top: 10px;
        }
    }

    .home__action {
        h2 {
            font-size: 22px;
        }

        h4 {
            font-size: 18px;
        }
    }

    .home__action--subtitle {
        font-size: 17px;
    }

    .home__action--image {
        width: 100%;
        margin: 30px auto 0;
    }

    .maatwerk__overview {
        .block__item {
            margin-bottom: 20px;
        }
    }

    .other__projects {
        padding: 30px 0 0;

        h3 {
            margin-bottom: 30px;
            font-size: 20px;
        }
    }

    .project__overlay {
        font-size: 16px;
    }

    .banner,
    .acf-map {
        margin-right: -15px;
        margin-left: -15px;
        height: 250px;
    }

    .categorie__block {
        padding: 30px 30px 0;
        margin-bottom: 10px;
    }

    .categorie__item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .categorie__item--image {
        margin-right: 0;
    }

    .categorie__item--content {
        text-align: center;
    }
}
