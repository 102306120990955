@media screen and (max-width: 1199px) {
    .header::before {
        background-size: 413px 222px;
        height: 222px;
        width: 413px;
    }

    footer::before {
        background-size: 222px 280px;
        height: 280px;
        width: 222px;
    }

    body {
        &.menu__open {
            .nav-primary__overlay {
                transition: all 300ms ease-in-out;
                opacity: 1;
                pointer-events: all;
            }

            .nav-primary {
                left: 0;
                transition: all 300ms ease-in-out;
            }
        }
    }

    .nav-primary__overlay {
        display: block;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease-in-out;
    }

    .menu__icon {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .nav-primary {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: -70%;
        width: 70%;
        height: 100%;
        background-color: $primarycolor;
        transition: all 300ms ease-in-out;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 0;

        a {
            color: #fff;

            &.nav__home {
                border-bottom: 1px solid darken($primarycolor, 5);
                color: #fff;
                justify-content: flex-start;
                padding: 26px 38px;
                width: 100%;
            }
        }
    }

    .nav-primary ul .fa-angle-down,
    .nav-primary li .fa-angle-down {
        float: right;
        margin-top: 6px;
    }

    .nav-primary .nav .sub-menu.sub-menu1 > li .fa-angle-right {
        float: right;
        margin-top: 6px;
        transform: rotate(90deg);
    }

    .nav-primary .nav > li {
        float: left;
        width: 100%;
        border-bottom: 1px solid darken($primarycolor, 5);
    }

    .nav-primary .nav .sub-menu {
        position: relative;
    }

    .nav-primary .nav .sub-menu.sub-menu1 {
        top: 0;
        left: 0;
        border-top: 1px solid darken($primarycolor, 10);
    }

    .nav-primary .nav .sub-menu.sub-menu1 > li a {
        padding-left: 25px;
    }

    .nav-primary .nav .sub-menu.sub-menu2 {
        top: 0;
        left: 0;
        border-top: 1px solid darken($primarycolor, 15);
    }

    .nav-primary .nav .sub-menu.sub-menu2 > li a {
        padding-left: 35px;
    }

    .nav-primary .nav .sub-menu.sub-menu3 {
        top: 0;
        left: 0;
        border-top: 1px solid darken($primarycolor, 20);
    }

    .nav-primary .nav .sub-menu.sub-menu3 > li a {
        padding-left: 45px;
    }

    .header {
        margin: 0;
    }

    .header__top--contact {
        display: block;
        text-align: right;
    }

    .header__advies,
    .header__openingstijden {
        width: calc(100% - 40px);
    }

    .header__afspraak {
        display: flex;
        align-items: center;
    }

    .home__navigation {
        margin: 0;

        .block__item.padding {
            width: calc(50% - 15px);
            margin-right: 30px;
            margin-bottom: 30px;
            display: flex;
            align-items: flex-start;

            &:nth-child(2n+2) {
                margin-right: 0;
            }

            &:last-child {
                left: 25%;
            }

            ul {
                display: block;
                margin: 0;
            }

            .block__item--image {
                width: 40%;
                float: left;
            }

            .block__item--content {
                width: 60%;
                float: left;
                padding-left: 20px;
            }

            .block__item--title {
                text-align: left;
                font-size: 17px;
                padding: 0 0 10px;
            }

            .btn-secondary {
                position: absolute;
                right: -15px;
                top: calc(50% - 15px);
                font-size: 0;
                height: 30px;
                width: 30px;
                padding: 0;
                display: none;
                // display: flex;
                // align-items: center;
                // justify-content: center;

                &::after {
                    font-size: 20px;
                    top: 2px;
                    right: 10px;
                }
            }
        }
    }

    .home__projecten--text {
        width: calc(50% + 70px);
        padding: 50px 35px 50px calc(8.33% + 35px);
    }

    .home__occasions--slider {
        width: calc(100% - 80px);
        margin-left: 40px;

        .home__occasions--item {
            padding-left: 10px;
            padding-right: 10px;
        }

        .slick-prev,
        .slick-next {
            width: 40px;
            height: 100%;

            &::before {
                position: absolute;
                font-size: 25px;
                font-family: 'Font Awesome 5 Pro';
                color: rgba(#313131, 0.29);
                opacity: 1;
            }
        }

        .slick-prev {
            left: -50px;

            &::before {
                right: 15px;
            }
        }

        .slick-next {
            right: -50px;

            &::before {
                left: 15px;
            }
        }
    }

    .sidebar__block--content {
        padding: 25px;
    }

    .timeline-block::before {
        margin-top: 68px;
        margin-left: -1px;
        left: 100%;
    }

    .timeline-item-left {
        width: 100%;
        padding-right: 4px;
        left: 0;
    }

    .timeline-item-right {
        width: 100%;
        padding-left: 4px;
        right: 0;
    }

    .timeline__circle {
        left: 50%;
        margin-left: -10px;
        top: 58px;

        &::before {
            width: 600px;
        }
    }

    .timeline__img {
        padding: 7px;
        margin-bottom: 30px;
    }

    .timeline__content {
        margin-top: 5px;

        h3 {
            font-size: 29px;
        }
    }

    .timeline-item {
        .timeline__content {
            width: 90%;
            text-align: left;
        }

        .timeline-item-left {
            .timeline__circle {
                right: -10px;
                left: auto;
            }
        }

        .timeline-item-right {
            .timeline__content {
                float: left;
            }

            .timeline__circle {
                right: -10px;
                transform: rotate(180deg);
                left: auto;
            }
        }

        &:last-child {
            &::after {
                top: 66px;
                right: -4px;
                margin-left: -4px;
                z-index: 1;
            }
        }
    }

    .timeline-overflow {
        overflow: inherit;

        .timeline__img {
            max-width: 320px;
        }
    }

    .timeline-item-left.timeline__img,
    .timeline-item-right .timeline__img {
        box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    }
}
