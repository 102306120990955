@media screen and (max-width: 991px) {
    .block__item--5 {
        margin: 0;

        .block__item.padding {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;

            &:last-child {
                left: 0;
            }
        }
    }

    .home__projecten--slider {
        width: 100%;

        .slick-dots {
            width: 100%;
            text-align: left;
            margin-right: 0;
        }
    }

    .home__projecten--slider-content {
        text-align: left;
        margin: 20px 0 10px;
    }

    .home__projecten--text {
        width: 100%;
        margin: 20px 0 0;
        padding: 50px;

        .btn-secondary {
            margin-top: 30px;
        }
    }

    .home__occasions {
        .btn-primary {
            margin-top: 25px;
            float: left;
        }
    }

    .sidebar__block {
        margin-top: 40px;
    }

    .project__overlay {
        font-size: 15px;
    }
}
